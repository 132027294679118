<template>
    <div class="Slider">
        <div class="Slider-inner">
            <input class="Slider-open" type="radio" id="Slider-1" name="Slider" aria-hidden="true" hidden=""
                checked="checked">
            <div class="Slider-item">
                <div class="relative overflow-hidden h-full hover:opacity-85">
                    <a href="/pianos/carl/legend/54">
                        <img class="object-cover saturate-150"
                            src="https://www.actonchina.com/imgs/IMG_UPL/20240701124152708.jpg">
                        <div class="absolute z-10 left-5 bottom-5">
                            <div class="grid grid-cols-1 justify-center items-center bg-rose-600/90">
                                <p class="px-4 pt-3 lg:text-lg font-bold text-white tracking-wider">续写传奇之音</p>
                                <p
                                    class="px-4 pt-2 pb-3 text-sm underline underline-offset-2 text-white tracking-wider">
                                    了解更多</p>
                            </div>
                        </div>
                        <div class="absolute z-10 left-5 top-5">
                            <p class="font-bold lg:text-2xl">LEGEND 传奇系列</p>
                        </div>
                    </a>
                </div>
            </div>
            <input class="Slider-open" type="radio" id="Slider-2" name="Slider" aria-hidden="true" hidden="">
            <div class="Slider-item">
                <div class="relative overflow-hidden h-full hover:opacity-85">
                    <a href="/carl/detail/412">
                        <img class="object-cover saturate-150"
                            src="https://www.actonchina.com/imgs/IMG_UPL/20230920171643322.jpg">
                        <div class="absolute z-10 left-5 bottom-5">
                            <div class="grid grid-cols-1 justify-center items-center bg-rose-600/90">
                                <p class="px-4 pt-3 lg:text-lg font-bold text-white tracking-wider">书写钢琴人生新动力</p>
                                <p
                                    class="px-4 pt-2 pb-3 text-sm underline underline-offset-2 text-white tracking-wider">
                                    了解更多</p>
                            </div>
                        </div>
                        <div class="absolute z-10 left-5 top-5">
                            <p class="font-bold lg:text-2xl">EDUCATION 教育系列 SE153G</p>
                        </div>
                    </a>
                </div>
            </div>
            <input class="Slider-open" type="radio" id="Slider-3" name="Slider" aria-hidden="true" hidden="">
            
            <label for="Slider-2" class="Slider-control next control-1">›</label>
            <label for="Slider-1" class="Slider-control prev control-2">‹</label>
            
            <label for="Slider-2" class="Slider-control prev control-1">‹</label>
            <label for="Slider-1" class="Slider-control next control-2">›</label>
            <ol class="Slider-indicators">
                <li>
                    <label for="Slider-1" class="Slider-bullet">•</label>
                </li>
                <li>
                    <label for="Slider-2" class="Slider-bullet">•</label>
                </li>
            </ol>
        </div>
    </div>
</template>


<style>
html,
body {
    margin: 0px;
    padding: 0px;
}

.Slider {
    position: relative;
    margin-top: 26px;
}

.Slider-inner {
    position: relative;
    overflow: hidden;
    width: 100%;
}

.Slider-open:checked+.Slider-item {
    position: static;
    opacity: 100;
}

.Slider-item {
    position: absolute;
    opacity: 0;
    -webkit-transition: opacity 0.6s ease-out;
    transition: opacity 0.6s ease-out;
}

.Slider-item img {
    display: block;
    height: auto;
    max-width: 100%;
}

.Slider-control {
    background: rgba(0, 0, 0, 0.28);
    border-radius: 50%;
    color: #fff;
    cursor: pointer;
    display: none;
    font-size: 40px;
    height: 40px;
    line-height: 35px;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    cursor: pointer;
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    text-align: center;
    width: 40px;
    z-index: 10;
}

.Slider-control.prev {
    left: 2%;
}

.Slider-control.next {
    right: 2%;
}

.Slider-control:hover {
    background: rgba(0, 0, 0, 0.8);
    color: #aaaaaa;
}

#Slider-1:checked~.control-1,
#Slider-2:checked~.control-2,
#Slider-3:checked~.control-3 {
    display: block;
}

.Slider-indicators {
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    bottom: 2%;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 10;
}

.Slider-indicators li {
    display: inline-block;
    margin: 0 5px;
}

.Slider-bullet {
    color: #fff;
    cursor: pointer;
    display: block;
    font-size: 35px;
}

.Slider-bullet:hover {
    color: #aaaaaa;
}

#Slider-1:checked~.control-1~.Slider-indicators li:nth-child(1) .Slider-bullet,
#Slider-2:checked~.control-2~.Slider-indicators li:nth-child(2) .Slider-bullet,
#Slider-3:checked~.control-3~.Slider-indicators li:nth-child(3) .Slider-bullet {
    color: #428bca;
}

#title {
    width: 100%;
    position: absolute;
    padding: 0px;
    margin: 0px auto;
    text-align: center;
    font-size: 27px;
    color: rgba(255, 255, 255, 1);
    font-family: 'Open Sans', sans-serif;
    z-index: 9999;
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.33), -1px 0px 2px rgba(255, 255, 255, 0);
}
</style>