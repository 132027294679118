<template>
  <div>
    <div class="flex flex-col items-center justify-center text-center py-5">
      <input class="border rounded px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500" v-model="question" placeholder="Ask a question" />
      <button class="block pt-5" @click="askExpert">提问</button>
      <p class="block pt-5 max-w-[900px] text-justify leading=loose">{{ answer }}</p>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';

const question = ref('');
const answer = ref('');

const askExpert = async () => {
  try {
    const response = await fetch(`/openai/ask/${encodeURIComponent(question.value)}`);
    const data = await response.json();
    if (data.error) {
      answer.value = 'Error: Invalid response from API.';
    } else {
      answer.value = data.answer;
    }
  } catch (error) {
    console.error('Error:', error);
    answer.value = 'Error: Something went wrong.';
  }
};
</script>