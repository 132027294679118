<template>
  <div class="container mx-auto pb-5 max-w-[1300px]">
    <div class="w-full">
      <img class="object-cover w-full" src="/images/head10.jpg">
    </div>
    <div class="py-6">
      <p class="font-bold tracking-wider text-2xl py-5 text-center">新闻与活动</p>
      <div class="grid grid-cols-1 gap-6 mx-5 lg:mx-0 lg:grid-cols-4 lg:gap-6">
        <div v-for="item in news" :key="item.id">
          <a class="relative" :href="`${item.n_url}`" target="_blank">
            <div class="hover:-translate-y-1 duration-150 hover:opacity-85">
              <div class="border border-yellow-600/20 p-1">
                <img class="object-cover w-auto saturate-150 lg:min-h-[190px]" :src="`${item.n_cover}`">
                <div class="pt-5 text-justify">
                  <p class="text-base font-bold lg:text-sm lg:min-h-[40px]">{{ item.n_title }}</p>
                  <p class="text-sm text-gray-500 pt-6 lg:min-h-[64px] lg:max-h-[64px] overflow-hidden">{{ item.n_brief
                    }}</p>
                  <p
                    class="flex justify-center content-center text-sm bg-slate-200/20 mt-4 py-2 text-center text-amber-700/80">
                    <svg class="m-[3px]" height="15" width="15" fill="none" viewBox="0 0 15 15"
                      xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_1276_2107)">
                        <path
                          d="M1.5625 4.0625C1.5625 2.68179 2.68179 1.5625 4.0625 1.5625C5.44321 1.5625 6.5625 2.68179 6.5625 4.0625C6.5625 5.44321 5.44321 6.5625 4.0625 6.5625C2.68179 6.5625 1.5625 5.44321 1.5625 4.0625Z"
                          stroke="#9D9FA4" strokeWidth="1.5" />
                        <path
                          d="M8.4375 10.9375C8.4375 9.55679 9.55679 8.4375 10.9375 8.4375C12.3182 8.4375 13.4375 9.55679 13.4375 10.9375C13.4375 12.3182 12.3182 13.4375 10.9375 13.4375C9.55679 13.4375 8.4375 12.3182 8.4375 10.9375Z"
                          stroke="#9D9FA4" strokeWidth="1.5" />
                        <path
                          d="M13.4375 4.0625C13.4375 2.88399 13.4375 2.29473 13.0714 1.92862C12.7053 1.5625 12.116 1.5625 10.9375 1.5625C9.75899 1.5625 9.16973 1.5625 8.80362 1.92862C8.4375 2.29473 8.4375 2.88399 8.4375 4.0625C8.4375 5.24101 8.4375 5.83027 8.80362 6.19638C9.16973 6.5625 9.75899 6.5625 10.9375 6.5625C12.116 6.5625 12.7053 6.5625 13.0714 6.19638C13.4375 5.83027 13.4375 5.24101 13.4375 4.0625Z"
                          stroke="#9D9FA4" strokeWidth="1.5" />
                        <path
                          d="M6.5625 10.9375C6.5625 9.75899 6.5625 9.16973 6.19638 8.80362C5.83027 8.4375 5.24101 8.4375 4.0625 8.4375C2.88399 8.4375 2.29473 8.4375 1.92862 8.80362C1.5625 9.16973 1.5625 9.75899 1.5625 10.9375C1.5625 12.116 1.5625 12.7053 1.92862 13.0714C2.29473 13.4375 2.88399 13.4375 4.0625 13.4375C5.24101 13.4375 5.83027 13.4375 6.19638 13.0714C6.5625 12.7053 6.5625 12.116 6.5625 10.9375Z"
                          stroke="#9D9FA4" strokeWidth="1.5" />
                      </g>
                      <defs>
                        <clipPath id="clip0_1276_2107">
                          <rect height="15" width="15" fill="white" rx="5" />
                        </clipPath>
                      </defs>
                    </svg>阅读
                  </p>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
const pageTitle = ref('最新新闻与活动信息 德国SAUTER（首德）钢琴购买指南');
const sauter = ref([]);
const carljohann = ref([]);
const news = ref([]);

const fetchProducts = async () => {
  try {
    const fetchData = async (url, num) => {
      const response = await fetch(url);
      const data = await response.json();
      return data.slice(0, num);
    };

    const newsArrays = await fetchData('/api/news/list/15', 30);
    news.value = newsArrays;
  } catch (error) {
    console.error('Error fetching products:', error);
  }
};

onMounted(() => {
  document.title = pageTitle.value;
  fetchProducts();
});
</script>