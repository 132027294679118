<template>
    <div class="">
        <div class="bg-gray-200/50 content-center min-h-[150px] grid font-sans">
            <p class="text-center font-bold text-3xl">完全德国制造</p>
        </div>
        <div class="py-5 bg-white">
            <div class="container mx-auto py-5 max-w-[900px]">
                <div class="grid grid-cols-1">
                    <div>
                        <div class="leading-7 pb-5" id="contact_sh">
                            <p class="font-bold text-2xl text-center font-mono">Made In Germany</p>
                            <p class="px-5 mx-auto text-center py-5 max-w-[500px]">
                                <img src="/images/madeinGM.png" class="object-cover mx-auto pb-5 max-w-40">
                                只使用德国生产的零部件，每个核心步骤皆由德国钢琴制造大师手工精心打造，这才是德国钢琴该有的样子。
                            </p>
                            <div class="text-center pt-10">
                                <a href="/madeinGermany"
                                    class="bg-red-600 rounded-3xl px-7 py-[12px] text-sm text-white tracking-wider hover:bg-red-500 duration-200">完全德国制造</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="py-5 bg-slate-200/40">
        <div class="container mx-auto pb-5 max-w-[1300px]">
            <div class="grid grid-cols-1 lg:grid-cols-2  place-items-center">
                <div class="pb-4 lg:py-4">
                    <img src="/images/factory.webp" class="object-cover lg:max-w-[500px]">
                </div>
                <div>
                    <div class="leading-7 pb-5" id="contact_sh">
                        <p class="font-bold text-lg xl:text-2xl text-center">探访德国 SAUTER 钢琴工厂</p>
                        <p class="px-5 mx-auto text-justify py-5 max-w-[500px]">
                            木材、皮革、毛毡、金属制成的零件组装成一个不可思议的演奏装置，大量的手工工序代表了两百多年的家族传承，凝聚了数代能工巧匠的智慧，也给我们带来了原汁原味的“完全德国制造”。</p>
                        <div class="text-center pt-10">
                            <a href="https://mp.weixin.qq.com/s/M5bEfs4zwJTRYfoi62r-NQ" target="_blank"
                                class="bg-red-600 rounded-3xl px-7 py-[12px] text-sm text-white tracking-wider hover:bg-red-500 duration-200">直击现场</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';

const pageTitle = ref('首德钢琴历史和首德之音的秘密');

onMounted(() => {
    document.title = pageTitle.value;
});
</script>