<template>
    <div class="bg-gray-200/50 content-center min-h-[150px] grid font-sans">
        <p class="text-center font-bold text-3xl">CONTACT</p>
    </div>
    <div class="container mx-auto py-5 max-w-[900px]">
        <div class="grid grid-cols-1 lg:grid-cols-2 mx-5">
            <div>
                <div class="leading-7 pb-5" id="contact_sh">
                    <p class="font-bold">SAUTER(首德) 钢琴 中国总代理</p>
                    <p class="font-bold">雅登音响乐器（上海）有限公司</p>
                    <p>地址：上海市静安区南京西路819号中创大厦1815室</p>
                    <p>邮编：200041</p>
                    <p>电话：021 - 6233 2626</p>
                </div>
                <div class="leading-7 pb-5" id="contact_bj">
                    <p class="font-bold">雅登音响乐器（上海）有限公司 北京分公司</p>
                    <p>地址：北京市朝阳区建国门外大街24号燕华苑2座2501座</p>
                    <p>邮编：100022</p>
                    <p>电话：010 - 8580 3355</p>
                </div>
                <div class="leading-7 pb-5" id="sales">
                    <p class="font-bold">销售与代理垂询</p>
                    <p>雅登上海 范先生 021 - 6233 2626</p>
                    <p>雅登北京 卢先生 010 - 8580 3355</p>
                </div>
                <div class="leading-7 pb-5" id="join">
                    <p class="font-bold">加入我们</p>
                    <p class=" flex">诚挚邀请热爱音乐的你，<a class="underline underline-offset-4"
                            href="https://www.actonchina.com/join/" target="_blank"> 加入雅登！</a>
                        <svg class="mt-1" height="20" width="20" fill="none" viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg">
                            <g clippath="url(#clip0_1276_7669)">
                                <path d="M7.5 12.5L12.5 7.5M12.5 7.5H8.75M12.5 7.5V11.25" stroke="#A97C0A"
                                    strokelinecap="round" strokelinejoin="round" strokewidth="1.5"></path>
                                <path
                                    d="M1.66675 10.0003C1.66675 6.07195 1.66675 4.10777 2.88714 2.88738C4.10752 1.66699 6.07171 1.66699 10.0001 1.66699C13.9285 1.66699 15.8926 1.66699 17.113 2.88738C18.3334 4.10777 18.3334 6.07195 18.3334 10.0003C18.3334 13.9287 18.3334 15.8929 17.113 17.1133C15.8926 18.3337 13.9285 18.3337 10.0001 18.3337C6.07171 18.3337 4.10752 18.3337 2.88714 17.1133C1.66675 15.8929 1.66675 13.9287 1.66675 10.0003Z"
                                    opacity="0.5" stroke="#A97C0A" strokewidth="1.5"></path>
                            </g>
                            <defs>
                                <clipPath id="clip0_1276_7669">
                                    <rect height="20" width="20" fill="white"></rect>
                                </clipPath>
                            </defs>
                        </svg>
                    </p>
                </div>
            </div>
            <div class="">
                <img src="/images/design-edition-1.jpg" class="object-cover">
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';

const pageTitle = ref('联系我们 德国SAUTER（首德）钢琴购买指南');

onMounted(() => {
    document.title = pageTitle.value;
});
</script>