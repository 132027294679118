<template>
    <div class="relative w-full">
        <div class="overflow-hidden relative min-h-[180px] max-h-[670px]" @touchstart="startTouch"
            @touchmove="moveTouch" @touchend="endTouch">
            <div class="flex transition-transform duration-700 ease-in-out"
                :style="{ transform: `translateX(-${currentIndex * 100}%)` }">
                <div v-for="(slide, index) in slides" :key="index" class="flex-shrink-0 w-full h-full">
                    <img :src="slide" class="w-full h-full object-cover" />
                </div>
            </div>
        </div>
        <button class="absolute top-1/2 left-2 transform -translate-y-1/2 p-2 bg-white bg-opacity-50 rounded-full"
            @click="prevSlide">
            <svg height="24" width="24" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M15 5L9 12L15 19" stroke="#1C274C" strokeLinecap="round" strokeLinejoin="round"
                    strokeWidth="1.5" />
            </svg>
        </button>
        <button class="absolute top-1/2 right-2 transform -translate-y-1/2 p-2 bg-white bg-opacity-50 rounded-full"
            @click="nextSlide">
            <svg height="24" width="24" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 5L15 12L9 19" stroke="#1C274C" strokeLinecap="round" strokeLinejoin="round"
                    strokeWidth="1.5" />
            </svg>
        </button>
        <div class="dot absolute bottom-0 left-1/2 transform -translate-x-1/2 flex space-x-2 p-2">
            <span v-for="(slide, index) in slides" :key="index" class="w-6 h-1 opacity-75 cursor-pointer" :class="{
            'bg-gray-700': index === currentIndex,
            'bg-gray-300': index !== currentIndex,
        }" @click="goToSlide(index)"></span>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';

const currentIndex = ref(0);
const slides = ref([
    '/images/banner7.jpg',
    '/images/banner8.jpg',
    '/images/banner9.jpg',
    '/images/banner10.jpg',
]);

const nextSlide = () => {
    currentIndex.value = (currentIndex.value + 1) % slides.value.length;
};

const prevSlide = () => {
    currentIndex.value = (currentIndex.value - 1 + slides.value.length) % slides.value.length;
};

const goToSlide = (index) => {
    currentIndex.value = index;
};

const autoSlide = () => {
    setInterval(() => {
        nextSlide();
    }, 5000);
};

let startX = 0;
let endX = 0;

const startTouch = (e) => {
    startX = e.touches[0].clientX;
};

const moveTouch = (e) => {
    endX = e.touches[0].clientX;
};

const endTouch = () => {
    if (startX > endX + 50) {
        nextSlide();
    } else if (startX < endX - 50) {
        prevSlide();
    }
};

onMounted(() => {
    autoSlide();
});
</script>

<style scoped>
.dot span {
    transition: background-color 0.3s;
}
</style>