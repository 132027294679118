<template class="">
  <div class="flex bg-gray-300/20 justify-center pt-3">
    <a href="/" class="text-2xl font-bold text-purple-600">
      <img class="object-cover colored-logo min-h-24" src="/sauterlogo.svg">
    </a>
  </div>
  <nav class="bg-gray-300/20 border-b border-gray-200 relative">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="flex justify-between h-16">
        <div class="flex">
          <div class="hidden lg:ml-6 lg:flex lg:space-x-8">
            <div @mouseenter="toggleDropdown('menu1')" @mouseleave="toggleDropdown('menu1')" class=" z-10 inline-flex">
              <button
                class="inline-flex items-center px-1 pt-1 hover:border-b-4 hover:border-yellow-600  font-medium text-gray-800">
                SAUTER 首德
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd"
                    d="M10 12a1 1 0 01-.707-.293l-4-4a1 1 0 111.414-1.414L10 9.586l3.293-3.293a1 1 0 111.414 1.414l-4 4A1 1 0 0110 12z"
                    clip-rule="evenodd" transform="translate(5, 2)" />
                  />
                </svg>
              </button>
              <transition enter-active-class="transition-opacity transition-transform duration-500 ease-out"
                enter-from-class="opacity-30 translate-y-[10px] " enter-to-class="opacity-100 translate-y-0"
                leave-active-class="transition-opacity transition-transform duration-300 ease-in"
                leave-from-class="opacity-100 translate-y-0" leave-to-class="opacity-0 translate-y-0">
                <div v-show="dropdown === 'menu1'"
                  class="absolute left-0 w-full mt-2 bg-white border border-gray-200 shadow-lg top-14">
                  <div class="flex mx-auto justify-center lg:max-w-[1300px] py-5">
                    <div class="grid grid-cols-2 gap-5">
                      <div class="col-span-2 flex mx-auto max-w-[130px] ">
                        <img class="object-cover grayscale" src="/images/sauterBadge.jpg">
                      </div>
                      <div class="col-span-2 text-center italic text-sm"><span
                          class="font-bold text-base">“完全德国制造”</span>一直是<span class="font-serif text-base"> SAUTER
                        </span>的标准。<br>历经200多年，时代浮沉更迭，始终不变。</div>
                      <a class="px-20" href="/pianos/sauter/grand/59">
                        <img class="object-cover min-h-40 max-h-40 border p-2 grayscale blur-[0.5px]"
                          src="https://www.actonchina.com/imgs/Product/20240606160654635.jpg">
                        <p
                          class="block text-center py-2 text-base font-bold text-gray-700 hover:bg-gray-100 underline decoration-2 decoration-red-600 underline-offset-8">
                          三角钢琴</p>
                      </a>
                      <a class="px-20" href="/pianos/sauter/upright/58">
                        <img class="object-cover min-h-40 max-h-40 border p-2 grayscale blur-[0.5px]"
                          src="https://www.actonchina.com//imgs/Product/20220923101328804.jpg">
                        <p
                          class="block text-center py-2 text-base font-bold text-gray-700 hover:bg-gray-100 underline decoration-2 decoration-red-600 underline-offset-8">
                          立式钢琴</p>
                      </a>
                    </div>
                  </div>
                </div>
              </transition>
            </div>
            <div @mouseenter="toggleDropdown('menu4')" @mouseleave="toggleDropdown('menu4')" class="z-10 inline-flex">
              <button
                class="inline-flex items-center px-1 pt-1 hover:border-b-4 hover:border-yellow-600  font-medium text-gray-800">
                Peter Maly 系列
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd"
                    d="M10 12a1 1 0 01-.707-.293l-4-4a1 1 0 111.414-1.414L10 9.586l3.293-3.293a1 1 0 111.414 1.414l-4 4A1 1 0 0110 12z"
                    clip-rule="evenodd" transform="translate(5, 2)" />
                  />
                </svg>
              </button>
              <transition enter-active-class="transition-opacity transition-transform duration-500 ease-out"
                enter-from-class="opacity-30 translate-y-[10px] " enter-to-class="opacity-100 translate-y-0"
                leave-active-class="transition-opacity transition-transform duration-300 ease-in"
                leave-from-class="opacity-100 translate-y-0" leave-to-class="opacity-0 translate-y-0">
                <div v-show="dropdown === 'menu4'"
                  class="absolute left-0 w-full mt-2 bg-white border border-gray-200 shadow-lg top-14">
                  <div class="flex mx-auto justify-center lg:max-w-[1300px] py-5">
                    <div class="grid grid-cols-2 gap-5">
                      <div class="col-span-2 flex mx-auto">
                        <img class="object-cover" src="/images/sauter-designed-by-maly.jpg">
                      </div>
                      <div class="col-span-2 text-center italic text-sm"><span
                          class="font-bold font-serif text-base">“Designed by Peter
                          Maly” </span><br>正如你看到的SAUTER 联名设计款钢琴，它就是钢琴界独树一帜的存在。</div>
                      <a class="px-20" href="/pianos/design/grand/61">
                        <img class="object-cover min-h-40 max-h-40 border p-2 grayscale blur-[0.5px]"
                          src="https://www.actonchina.com/imgs/Product/20240606153901434.jpg">
                        <p
                          class="block text-center py-2 text-base font-bold text-gray-700 hover:bg-gray-100 underline decoration-2 decoration-red-600 underline-offset-8">
                          三角钢琴</p>
                      </a>
                      <a class="px-20" href="/pianos/design/upright/62">
                        <img class="object-cover min-h-40 max-h-40 border p-2 grayscale blur-[0.5px]"
                          src="https://www.actonchina.com//imgs/Product/20191021140651933.jpg">
                        <p
                          class="block text-center py-2 text-base font-bold text-gray-700 hover:bg-gray-100 underline decoration-2 decoration-red-600 underline-offset-8">
                          立式钢琴</p>
                      </a>
                    </div>
                  </div>
                </div>
              </transition>
            </div>
            <div @mouseenter="toggleDropdown('menu2')" @mouseleave="toggleDropdown('menu2')" class="z-10 inline-flex">
              <button
                class="inline-flex items-center px-1 pt-1 hover:border-b-4 hover:border-yellow-600  font-medium text-gray-800">
                Carl Johann 卡尔·约翰
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd"
                    d="M10 12a1 1 0 01-.707-.293l-4-4a1 1 0 111.414-1.414L10 9.586l3.293-3.293a1 1 0 111.414 1.414l-4 4A1 1 0 0110 12z"
                    clip-rule="evenodd" transform="translate(5, 2)" />
                  />
                </svg>
              </button>
              <transition enter-active-class="transition-opacity transition-transform duration-500 ease-out"
                enter-from-class="opacity-30 translate-y-[10px] " enter-to-class="opacity-100 translate-y-0"
                leave-active-class="transition-opacity transition-transform duration-300 ease-in"
                leave-from-class="opacity-100 translate-y-0" leave-to-class="opacity-0 translate-y-0">
                <div v-show="dropdown === 'menu2'"
                  class="absolute left-0 w-full mt-2 bg-white border border-gray-200 shadow-lg top-14">
                  <div class="flex mx-auto justify-center lg:max-w-[1300px] py-5">
                    <div class="grid grid-cols-4 gap-1 lg:min-w-[1000]">
                      <div class="col-span-4 flex mx-auto">
                        <img class="object-cover" src="https://www.actonchina.com/imgs/Brand/20180423134145035.jpg">
                      </div>
                      <div class="col-span-4 text-center italic text-sm pb-3"><span
                          class="font-bold font-serif text-base">Carl Johann
                          (卡尔·约翰)</span><br>德国SAUTER钢琴的家族品牌之一，为致敬SAUTER家族百年传承而生。
                      </div>
                      <a class="px-5" href="/pianos/carl/legend/54">
                        <img class="object-cover min-h-40 max-h-40 border p-2 grayscale blur-[0.5px]"
                          src="https://www.actonchina.com//imgs/Product/20240701114353312.jpg">
                        <p
                          class="block text-center py-2 text-base font-bold text-gray-700 hover:bg-gray-100 underline decoration-2 decoration-red-600 underline-offset-8">
                          LEGEND系列</p>
                      </a>
                      <a class="px-5" href="/pianos/carl/classic/52">
                        <img class="object-cover min-h-40 max-h-40 border p-2 grayscale blur-[0.5px]"
                          src="https://www.actonchina.com//imgs/Product/20190228150052376.jpg">
                        <p
                          class="block text-center py-2 text-base font-bold text-gray-700 hover:bg-gray-100 underline decoration-2 decoration-red-600 underline-offset-8">
                          CLASSIC系列</p>
                      </a>
                      <a class="px-5" href="/pianos/carl/vintage/40">
                        <img class="object-cover min-h-40 max-h-40 border p-2 grayscale blur-[0.5px]"
                          src="https://www.actonchina.com//imgs/Product/20230111160625904.jpg">
                        <p
                          class="block text-center py-2 text-base font-bold text-gray-700 hover:bg-gray-100 underline decoration-2 decoration-red-600 underline-offset-8">
                          VINTAGE复古系列</p>
                      </a>
                      <a class="px-5" href="/pianos/carl/se/39">
                        <img class="object-cover min-h-40 max-h-40 border p-2 grayscale blur-[0.5px]"
                          src="https://www.actonchina.com//imgs/Product/20230630151050211.jpg">
                        <p
                          class="block text-center py-2 text-base font-bold text-gray-700 hover:bg-gray-100 underline decoration-2 decoration-red-600 underline-offset-8">
                          SE系列</p>
                      </a>
                    </div>
                  </div>
                </div>
              </transition>
            </div>
            <a href="/news/"
              class="inline-flex items-center px-1 pt-1 hover:border-b-4 hover:border-yellow-600  font-medium text-gray-800">新闻中心
            </a>
            <a href="https://space.bilibili.com/479444456" target="_blank"
              class="inline-flex items-center px-1 pt-1 hover:border-b-4 hover:border-yellow-600  font-medium text-gray-800">影音频道
            </a>
            <div @mouseenter="toggleDropdown('menu5')" @mouseleave="toggleDropdown('menu5')" class="z-10 inline-flex">
              <button
                class="inline-flex items-center px-1 pt-1 hover:border-b-4 hover:border-yellow-600  font-medium text-gray-800">
                探索首德
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd"
                    d="M10 12a1 1 0 01-.707-.293l-4-4a1 1 0 111.414-1.414L10 9.586l3.293-3.293a1 1 0 111.414 1.414l-4 4A1 1 0 0110 12z"
                    clip-rule="evenodd" transform="translate(5, 2)" />
                  />
                </svg>
              </button>
              <transition enter-active-class="transition-opacity transition-transform duration-500 ease-out"
                enter-from-class="opacity-30 translate-y-[10px] " enter-to-class="opacity-100 translate-y-0"
                leave-active-class="transition-opacity transition-transform duration-300 ease-in"
                leave-from-class="opacity-100 translate-y-0" leave-to-class="opacity-0 translate-y-0">
                <div v-show="dropdown === 'menu5'"
                  class="absolute left-0 w-full mt-2 bg-white border border-gray-200 shadow-lg top-14">
                  <div class="flex mx-auto lg:max-w-[1300px] py-10">
                    <div class="grid grid-cols-4 gap-2">
                      <a class="px-10" href="/history">
                        <div class="p-1 mb-4 min-w-60 border-solid border border-yellow-600/50">
                          <img class="object-cover w-full max-h-24 grayscale" src="/images/history.jpg">
                        </div>
                        <p class="md:min-w-[230px] text-justify">始于1819年的施派欣根，205年的历史耀眼夺目，在整个欧洲制琴史上是一颗璀璨的明珠。</p>
                        <p
                          class="block py-2 text-base font-bold text-gray-700 underline decoration-2 decoration-red-600 underline-offset-8">
                          首德钢琴历史</p>
                      </a>
                      <a class="px-10" href="/explorer">
                        <div class="p-1 mb-4 min-w-60 border-solid border border-yellow-600/50">
                          <img class="object-cover w-full max-h-24 grayscale" src="/images/factory.webp">
                        </div>
                        <p class="md:min-w-[230px] text-justify">只使用德国生产的零部件，每个核心步骤皆由德国钢琴制造大师手工精心打造。</p>
                        <p
                          class="block py-2 text-base font-bold text-gray-700 underline decoration-2 decoration-red-600 underline-offset-8">
                          完全德国制造</p>
                      </a>
                      <a class="px-10" href="/sound">
                        <div class="p-1 mb-4 min-w-60 border-solid border border-yellow-600/50">
                          <img class="object-cover w-full max-h-24 grayscale" src="/images/sound_4.webp">
                        </div>
                        <p class="md:min-w-[230px] text-justify">将声音从钢琴中解放出来，让它的个性和声音整体得到充分的发展，为他们注入灵魂和生命。</p>
                        <p
                          class="block py-2 text-base font-bold text-gray-700 underline decoration-2 decoration-red-600 underline-offset-8">
                          首德之音的秘密</p>
                      </a>
                      <a class="px-10 place-items-center flex" href="/media">
                        <svg class="mr-4" height="50" width="50" fill="none" viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M12 2.75C6.89137 2.75 2.75 6.89137 2.75 12C2.75 17.1086 6.89137 21.25 12 21.25C17.1086 21.25 21.25 17.1086 21.25 12C21.25 6.89137 17.1086 2.75 12 2.75ZM1.25 12C1.25 6.06294 6.06294 1.25 12 1.25C17.9371 1.25 22.75 6.06294 22.75 12C22.75 17.9371 17.9371 22.75 12 22.75C6.06294 22.75 1.25 17.9371 1.25 12ZM9.96967 8.46967C10.2626 8.17678 10.7374 8.17678 11.0303 8.46967L14.0303 11.4697C14.3232 11.7626 14.3232 12.2374 14.0303 12.5303L11.0303 15.5303C10.7374 15.8232 10.2626 15.8232 9.96967 15.5303C9.67678 15.2374 9.67678 14.7626 9.96967 14.4697L12.4393 12L9.96967 9.53033C9.67678 9.23744 9.67678 8.76256 9.96967 8.46967Z"
                            fill="#C0C0C0" fillRule="evenodd" />
                        </svg>
                        <div class="grid grid-cols-1">
                          <p class="text-sm ">探索更多信息</p>
                          <p
                            class="py-2 font-bold text-gray-700 underline decoration-2 decoration-red-600 underline-offset-8">
                            媒体频道
                          </p>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </transition>
            </div>
            <div @mouseenter="toggleDropdown('menu3')" @mouseleave="toggleDropdown('menu3')" class="z-10 inline-flex">
              <button
                class="inline-flex items-center px-1 pt-1 hover:border-b-4 hover:border-yellow-600  font-medium text-gray-800">
                服务与支持
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd"
                    d="M10 12a1 1 0 01-.707-.293l-4-4a1 1 0 111.414-1.414L10 9.586l3.293-3.293a1 1 0 111.414 1.414l-4 4A1 1 0 0110 12z"
                    clip-rule="evenodd" transform="translate(5, 2)" />
                  />
                </svg>
              </button>
              <transition enter-active-class="transition-opacity transition-transform duration-500 ease-out"
                enter-from-class="opacity-30 translate-y-[10px] " enter-to-class="opacity-100 translate-y-0"
                leave-active-class="transition-opacity transition-transform duration-300 ease-in"
                leave-from-class="opacity-100 translate-y-0" leave-to-class="opacity-0 translate-y-0">
                <div v-show="dropdown === 'menu3'"
                  class="absolute left-0 w-full mt-2 bg-white border border-gray-200 shadow-lg top-14">
                  <div class="flex mx-auto justify-center lg:max-w-[1300px] py-10">
                    <div class="grid grid-cols-3 gap-20 lg:max-w-[900px]">
                      <div class="">
                        <p class="font-bold text-lg pb-4 flex">
                          <svg class="mr-2" height="24" width="24" fill="none" viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M3.74181 20.5545C4.94143 22 7.17414 22 11.6395 22H12.3607C16.8261 22 19.0589 22 20.2585 20.5545M3.74181 20.5545C2.54219 19.1091 2.95365 16.9146 3.77657 12.5257C4.36179 9.40452 4.65441 7.84393 5.7653 6.92196M3.74181 20.5545C3.74181 20.5545 3.74181 20.5545 3.74181 20.5545ZM20.2585 20.5545C21.4581 19.1091 21.0466 16.9146 20.2237 12.5257C19.6385 9.40452 19.3459 7.84393 18.235 6.92196M20.2585 20.5545C20.2585 20.5545 20.2585 20.5545 20.2585 20.5545ZM18.235 6.92196C17.1241 6 15.5363 6 12.3607 6H11.6395C8.46398 6 6.8762 6 5.7653 6.92196M18.235 6.92196C18.235 6.92196 18.235 6.92196 18.235 6.92196ZM5.7653 6.92196C5.7653 6.92196 5.7653 6.92196 5.7653 6.92196Z"
                              stroke="#1C274C" strokeWidth="1.5" />
                            <path d="M10 14.3C10.5207 14.7686 10.8126 15.0314 11.3333 15.5L14 12.5" stroke="#1C274C"
                              strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                            <path d="M9 6V5C9 3.34315 10.3431 2 12 2C13.6569 2 15 3.34315 15 5V6" stroke="#1C274C"
                              strokeLinecap="round" strokeWidth="1.5" />
                          </svg>
                          购买
                        </p>
                        <a href="/dealer/" class="block py-2 text-gray-900">授权经销商</a>
                        <!-- <a href="/studio/" class="block py-2 text-gray-900">首德工作室</a> -->
                        <a href="/Technicians/" class="block py-2 text-gray-900">认证钢琴技师</a>
                      </div>
                      <div class="">
                        <p class="font-bold text-lg pb-4 flex">
                          <svg class="mr-2" height="24" width="24" fill="none" viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M18 6V10.8528C18 11.1429 18 11.2879 17.9051 11.3465C17.8103 11.4051 17.6806 11.3403 17.4211 11.2106L16.1789 10.5894C16.0911 10.5456 16.0472 10.5236 16 10.5236C15.9528 10.5236 15.9089 10.5456 15.8211 10.5894L14.5789 11.2106C14.3194 11.3403 14.1897 11.4051 14.0949 11.3465C14 11.2879 14 11.1429 14 10.8528V6"
                              stroke="#1C274C" strokeWidth="1.5"></path>
                            <path
                              d="M2 6.94975C2 6.06722 2 5.62595 2.06935 5.25839C2.37464 3.64031 3.64031 2.37464 5.25839 2.06935C5.62595 2 6.06722 2 6.94975 2C7.33642 2 7.52976 2 7.71557 2.01738C8.51665 2.09229 9.27652 2.40704 9.89594 2.92051C10.0396 3.03961 10.1763 3.17633 10.4497 3.44975L11 4C11.8158 4.81578 12.2237 5.22367 12.7121 5.49543C12.9804 5.64471 13.2651 5.7626 13.5604 5.84678C14.0979 6 14.6747 6 15.8284 6H16.2021C18.8345 6 20.1506 6 21.0062 6.76946C21.0849 6.84024 21.1598 6.91514 21.2305 6.99383C22 7.84935 22 9.16554 22 11.7979V14C22 17.7712 22 19.6569 20.8284 20.8284C19.6569 22 17.7712 22 14 22H10C6.22876 22 4.34315 22 3.17157 20.8284C2 19.6569 2 17.7712 2 14V6.94975Z"
                              stroke="#1C274C" strokeWidth="1.5"></path>
                          </svg>
                          服务
                        </p>
                        <a href="/validation" class="block py-2 text-gray-900">正品验证</a>
                        <a href="/care" class="block py-2 text-gray-900">售后支持</a>
                      </div>
                      <div class="">
                        <p class="font-bold text-lg pb-4 flex">
                          <svg class="mr-2" height="24" width="24" fill="none" viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M17 12C19.7614 12 22 9.76142 22 7C22 4.23858 19.7614 2 17 2C14.2386 2 12 4.23858 12 7C12 7.79984 12.1878 8.55582 12.5217 9.22624C12.6105 9.4044 12.64 9.60803 12.5886 9.80031L12.2908 10.9133C12.1615 11.3965 12.6035 11.8385 13.0867 11.7092L14.1997 11.4114C14.392 11.36 14.5956 11.3895 14.7738 11.4783C15.4442 11.8122 16.2002 12 17 12Z"
                              stroke="#1C274C" strokeWidth="1.5" />
                            <path
                              d="M8.03759 7.31617L8.6866 8.4791C9.2723 9.52858 9.03718 10.9053 8.11471 11.8278C8.11471 11.8278 8.11471 11.8278 8.11471 11.8278C8.11459 11.8279 6.99588 12.9468 9.02451 14.9755C11.0525 17.0035 12.1714 15.8861 12.1722 15.8853C12.1722 15.8853 12.1722 15.8853 12.1722 15.8853C13.0947 14.9628 14.4714 14.7277 15.5209 15.3134L16.6838 15.9624C18.2686 16.8468 18.4557 19.0692 17.0628 20.4622C16.2258 21.2992 15.2004 21.9505 14.0669 21.9934C12.1588 22.0658 8.91828 21.5829 5.6677 18.3323C2.41713 15.0817 1.93421 11.8412 2.00655 9.93309C2.04952 8.7996 2.7008 7.77423 3.53781 6.93723C4.93076 5.54428 7.15317 5.73144 8.03759 7.31617Z"
                              stroke="#1C274C" strokeLinecap="round" strokeWidth="1.5" />
                          </svg>
                          支持
                        </p>
                        <a href="/contact/" class="block py-2 text-gray-900 ">联系我们</a>
                        <a href="/download/" class="block py-2 text-gray-900">下载中心</a>
                      </div>
                    </div>
                  </div>
                </div>
              </transition>
            </div>
          </div>
        </div>
        <div class="hidden lg:hidden xl:flex xl:items-center">
          <a href="/dealer" class="p-2 text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500"
            alt="授权经销商" title="授权经销商">
            <span class="sr-only">经销商</span>
            <svg height="24" width="24" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M3.74181 20.5545C4.94143 22 7.17414 22 11.6395 22H12.3607C16.8261 22 19.0589 22 20.2585 20.5545M3.74181 20.5545C2.54219 19.1091 2.95365 16.9146 3.77657 12.5257C4.36179 9.40452 4.65441 7.84393 5.7653 6.92196M3.74181 20.5545C3.74181 20.5545 3.74181 20.5545 3.74181 20.5545ZM20.2585 20.5545C21.4581 19.1091 21.0466 16.9146 20.2237 12.5257C19.6385 9.40452 19.3459 7.84393 18.235 6.92196M20.2585 20.5545C20.2585 20.5545 20.2585 20.5545 20.2585 20.5545ZM18.235 6.92196C17.1241 6 15.5363 6 12.3607 6H11.6395C8.46398 6 6.8762 6 5.7653 6.92196M18.235 6.92196C18.235 6.92196 18.235 6.92196 18.235 6.92196ZM5.7653 6.92196C5.7653 6.92196 5.7653 6.92196 5.7653 6.92196Z"
                stroke="#1C274C" strokeWidth="1.5" />
              <path d="M10 14.3C10.5207 14.7686 10.8126 15.0314 11.3333 15.5L14 12.5" stroke="#1C274C"
                strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
              <path d="M9 6V5C9 3.34315 10.3431 2 12 2C13.6569 2 15 3.34315 15 5V6" stroke="#1C274C"
                strokeLinecap="round" strokeWidth="1.5" />
            </svg>
          </a>
          <a href="/contact" class="p-2 text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500"
            alt="联系我们" title="联系我们">
            <span class="sr-only">联系我们</span>
            <svg height="24" width="24" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M17 12C19.7614 12 22 9.76142 22 7C22 4.23858 19.7614 2 17 2C14.2386 2 12 4.23858 12 7C12 7.79984 12.1878 8.55582 12.5217 9.22624C12.6105 9.4044 12.64 9.60803 12.5886 9.80031L12.2908 10.9133C12.1615 11.3965 12.6035 11.8385 13.0867 11.7092L14.1997 11.4114C14.392 11.36 14.5956 11.3895 14.7738 11.4783C15.4442 11.8122 16.2002 12 17 12Z"
                stroke="#1C274C" strokeWidth="1.5" />
              <path
                d="M8.03759 7.31617L8.6866 8.4791C9.2723 9.52858 9.03718 10.9053 8.11471 11.8278C8.11471 11.8278 8.11471 11.8278 8.11471 11.8278C8.11459 11.8279 6.99588 12.9468 9.02451 14.9755C11.0525 17.0035 12.1714 15.8861 12.1722 15.8853C12.1722 15.8853 12.1722 15.8853 12.1722 15.8853C13.0947 14.9628 14.4714 14.7277 15.5209 15.3134L16.6838 15.9624C18.2686 16.8468 18.4557 19.0692 17.0628 20.4622C16.2258 21.2992 15.2004 21.9505 14.0669 21.9934C12.1588 22.0658 8.91828 21.5829 5.6677 18.3323C2.41713 15.0817 1.93421 11.8412 2.00655 9.93309C2.04952 8.7996 2.7008 7.77423 3.53781 6.93723C4.93076 5.54428 7.15317 5.73144 8.03759 7.31617Z"
                stroke="#1C274C" strokeLinecap="round" strokeWidth="1.5" />
            </svg>
          </a>
          <a href="https://www.sauter-pianos.de" target="_blank"
            class="p-2 text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500" alt="英文站"
            title="访问首德德国">
            <span class="sr-only">英文站</span>
            <svg height="24" width="24" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M22 12C22 13.3132 21.7413 14.6136 21.2388 15.8268C20.7362 17.0401 19.9997 18.1425 19.0711 19.0711C18.1425 19.9997 17.0401 20.7362 15.8268 21.2388C14.6136 21.7413 13.3132 22 12 22C10.6868 22 9.38642 21.7413 8.17317 21.2388C6.95991 20.7362 5.85752 19.9997 4.92893 19.0711C4.00035 18.1425 3.26375 17.0401 2.7612 15.8268C2.25866 14.6136 2 13.3132 2 12C2 10.6868 2.25866 9.38642 2.76121 8.17316C3.26375 6.95991 4.00035 5.85752 4.92893 4.92893C5.85752 4.00035 6.95991 3.26375 8.17317 2.7612C9.38642 2.25866 10.6868 2 12 2C13.3132 2 14.6136 2.25866 15.8268 2.76121C17.0401 3.26375 18.1425 4.00035 19.0711 4.92893C19.9997 5.85752 20.7362 6.95991 21.2388 8.17317C21.7413 9.38642 22 10.6868 22 12L22 12Z"
                stroke="#1C274C" strokeWidth="1.5" />
              <path
                d="M16 12C16 13.3132 15.8965 14.6136 15.6955 15.8268C15.4945 17.0401 15.1999 18.1425 14.8284 19.0711C14.457 19.9997 14.016 20.7362 13.5307 21.2388C13.0454 21.7413 12.5253 22 12 22C11.4747 22 10.9546 21.7413 10.4693 21.2388C9.98396 20.7362 9.54301 19.9997 9.17157 19.0711C8.80014 18.1425 8.5055 17.0401 8.30448 15.8268C8.10346 14.6136 8 13.3132 8 12C8 10.6868 8.10346 9.38642 8.30448 8.17316C8.5055 6.95991 8.80014 5.85752 9.17157 4.92893C9.54301 4.00035 9.98396 3.26375 10.4693 2.7612C10.9546 2.25866 11.4747 2 12 2C12.5253 2 13.0454 2.25866 13.5307 2.76121C14.016 3.26375 14.457 4.00035 14.8284 4.92893C15.1999 5.85752 15.4945 6.95991 15.6955 8.17317C15.8965 9.38642 16 10.6868 16 12L16 12Z"
                stroke="#1C274C" strokeWidth="1.5" />
              <path d="M2 12H22" stroke="#1C274C" strokeLinecap="round" strokeWidth="1.5" />
            </svg>
          </a>
        </div>
        <div class="-mr-2 flex items-center lg:hidden">
          <button @click="toggleMenu"
            class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500">
            <span class="sr-only">Open main menu</span>
            <svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
              <path :class="{ 'hidden': isOpen, 'inline-flex': !isOpen }" class="inline-flex" stroke-linecap="round"
                stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16m-7 6h7"></path>
              <path :class="{ 'hidden': !isOpen, 'inline-flex': isOpen }" class="hidden" stroke-linecap="round"
                stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
            </svg>
          </button>
        </div>
      </div>
    </div>
    <!--Menu for Responsible-->
    <div
      :class="['toggleMenu fixed z-50 top-0 left-0 h-full w-full opacity-95 bg-gray-900 text-white transition-transform transform', { '-translate-x-full': !isMenuOpen }]"
      @click.self="toggleMenu">
      <SubMenuHead />
      <div class="p-4 mt-5">
        <div class="py-2 flex items-center justify-between border-b border-gray-600/70 min-h-16"
          @click="toggleSubMenu('item1')">
          <p class="text-sm">SAUTER 首德钢琴</p>
          <p><svg height="24" width="24" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M9 5L15 12L9 19" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round"
                strokeWidth="1.5" />
            </svg></p>
        </div>
        <div class="py-2 flex items-center justify-between border-b border-gray-600/70 min-h-16"
          @click="toggleSubMenu('item2')">
          <p class="text-sm">Peter Maly 系列钢琴</p>
          <p><svg height="24" width="24" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M9 5L15 12L9 19" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round"
                strokeWidth="1.5" />
            </svg></p>
        </div>
        <div class="py-2 flex items-center justify-between border-b border-gray-600/70 min-h-16"
          @click="toggleSubMenu('item3')">
          <p class="text-sm">CARL JOHAANN 卡尔·约翰钢琴</p>
          <p><svg height="24" width="24" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M9 5L15 12L9 19" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round"
                strokeWidth="1.5" />
            </svg></p>
        </div>
        <a href="/news">
          <div class="py-2 flex items-center justify-between border-b border-gray-600/70 min-h-16">
            <p class="text-sm">新闻中心</p>
          </div>
        </a>
        <a href="https://space.bilibili.com/479444456" target="_blank">
          <div class="py-2 flex items-center justify-between border-b border-gray-600/70 min-h-16">
            <p class="text-sm">影音频道</p>
          </div>
        </a>
        <div class="py-2 flex items-center justify-between border-b border-gray-600/70 min-h-16"
          @click="toggleSubMenu('item4')">
          <p class="text-sm">探索首德</p>
          <p><svg height="24" width="24" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M9 5L15 12L9 19" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round"
                strokeWidth="1.5" />
            </svg></p>
        </div>
        <div class="py-2 flex items-center justify-between border-b border-gray-600/70 min-h-16"
          @click="toggleSubMenu('item5')">
          <p class="text-sm">服务与支持</p>
          <p><svg height="24" width="24" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M9 5L15 12L9 19" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round"
                strokeWidth="1.5" />
            </svg></p>
        </div>
      </div>

      <!-- SubMenu for item1 -->
      <div v-if="isSubMenuOpen && activeSubMenu === 'item1'"
        :class="['fixed z-51 top-0 left-0 h-full w-full opacity-95 bg-gray-900 text-white transition-transform transform', { '-translate-x-full': !isSubMenuOpen }]"
        @click.self="toggleMenu">
        <SubMenuHead />
        <div class="p-4 mt-5">
          <a href="/pianos/sauter/grand/59">
            <div class="py-2 flex items-center justify-between border-b border-gray-600/70 min-h-16 text-sm">三角钢琴</div>
          </a>
          <a href="/pianos/sauter/upright/58">
            <div class="py-2 flex items-center justify-between border-b border-gray-600/70 min-h-16 text-sm">立式钢琴</div>
          </a>
        </div>
      </div>

      <div v-if="isSubMenuOpen && activeSubMenu === 'item2'"
        class="fixed top-0 left-0 h-full w-full opacity-95 bg-gray-900 text-white transition-transform transform"
        @click.self="toggleMenu">
        <SubMenuHead />
        <div class="p-4 mt-5">
          <a href="/pianos/design/grand/61">
            <div class="py-2 flex items-center justify-between border-b border-gray-600/70 min-h-16 text-sm">
              三角钢琴
            </div>
          </a>
          <a href="/pianos/design/upright/62">
            <div class="py-2 flex items-center justify-between border-b border-gray-600/70 min-h-16 text-sm">
              立式钢琴
            </div>
          </a>
        </div>
      </div>

      <div v-if="isSubMenuOpen && activeSubMenu === 'item3'"
        class="fixed top-0 left-0 h-full w-full opacity-95 bg-gray-900 text-white transition-transform transform"
        @click.self="toggleMenu">
        <SubMenuHead />
        <div class="p-4 mt-5">
          <a href="/pianos/carl/legend/54">
            <div class="py-2 flex items-center justify-between border-b border-gray-600/70 min-h-16 text-sm">
              LEGEND 系列
            </div>
          </a>
          <a href="/pianos/carl/classic/52">
            <div class="py-2 flex items-center justify-between border-b border-gray-600/70 min-h-16 text-sm">
              CLASSIC 系列
            </div>
          </a>
          <a href="/pianos/carl/vintage/40">
            <div class="py-2 flex items-center justify-between border-b border-gray-600/70 min-h-16 text-sm">
              VINTAGE 系列
            </div>
          </a>
          <a href="/pianos/carl/se/39">
            <div class="py-2 flex items-center justify-between border-b border-gray-600/70 min-h-16 text-sm">
              SAUTER EDUCATION 系列
            </div>
          </a>
        </div>
      </div>

      <div v-if="isSubMenuOpen && activeSubMenu === 'item4'"
        class="fixed top-0 left-0 h-full w-full opacity-95 bg-gray-900 text-white transition-transform transform"
        @click.self="toggleMenu">
        <SubMenuHead />
        <div class="p-4 mt-5">
          <a href="/history">
            <div class="py-2 flex items-center justify-between border-b border-gray-600/70 min-h-16 text-sm">
              首德钢琴历史
            </div>
          </a>
          <a href="/explorer">
            <div class="py-2 flex items-center justify-between border-b border-gray-600/70 min-h-16 text-sm">
              完全德国制造
            </div>
          </a>
          <a href="/sound">
            <div class="py-2 flex items-center justify-between border-b border-gray-600/70 min-h-16 text-sm">
              首德之音的秘密
            </div>
          </a>
          <a href="/media/">
            <div class="py-2 flex items-center justify-between border-b border-gray-600/70 min-h-16 text-sm">
              媒体频道
            </div>
          </a>
        </div>
      </div>

      <div v-if="isSubMenuOpen && activeSubMenu === 'item5'"
        class="fixed top-0 left-0 h-full w-full opacity-95 bg-gray-900 text-white transition-transform transform"
        @click.self="toggleMenu">
        <SubMenuHead />
        <div class="p-4 mt-5">
          <a href="/dealer/">
            <div class="py-2 flex items-center justify-between border-b border-gray-600/70 min-h-16 text-sm">
              授权经销商
            </div>
          </a>
          <!-- <a href="/studio/">
            <div class="py-2 flex items-center justify-between border-b border-gray-600/70 min-h-16 text-sm">
              首德工作室
            </div>
          </a> -->
          <a href="/Technicians/">
            <div class="py-2 flex items-center justify-between border-b border-gray-600/70 min-h-16 text-sm">
              认证钢琴技师
            </div>
          </a>
          <a href="/validation">
            <div class="py-2 flex items-center justify-between border-b border-gray-600/70 min-h-16 text-sm">
              正品验证
            </div>
          </a>
          <a href="care">
            <div class="py-2 flex items-center justify-between border-b border-gray-600/70 min-h-16 text-sm">
              售后支持
            </div>
          </a>
          <a href="/contact/">
            <div class="py-2 flex items-center justify-between border-b border-gray-600/70 min-h-16 text-sm">
              联系我们
            </div>
          </a>
          <a href="/download/">
            <div class="py-2 flex items-center justify-between border-b border-gray-600/70 min-h-16 text-sm">
              下载中心
            </div>
          </a>

        </div>
      </div>
    </div>


  </nav>
</template>

<script setup>
import { ref } from 'vue';
import SubMenuHead from '@/components/common/SubMenuHead.vue';

const isOpen = ref(false);
const isMenuOpen = ref(false);
const isSubMenuOpen = ref(false);
const activeSubMenu = ref('');
const dropdown = ref(null);

const toggleMenu = () => {
  isMenuOpen.value = !isMenuOpen.value;
  if (!isMenuOpen.value) {
    isSubMenuOpen.value = false;
    activeSubMenu.value = '';
    dropdown.value = null;
  }
};

const toggleSubMenu = (menu) => {
  if (activeSubMenu.value === menu) {
    isSubMenuOpen.value = !isSubMenuOpen.value;
    if (!isSubMenuOpen.value) {
      activeSubMenu.value = '';
    }
  } else {
    isSubMenuOpen.value = true;
    activeSubMenu.value = menu;
  }
};

const toggleDropdown = (menu) => {
  dropdown.value = dropdown.value === menu ? null : menu;
};

</script>



<style scoped>
.colored-logo {
  filter: invert(100%);
  /* 将SVG图片反相 */
}
</style>