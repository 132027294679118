<template>
    <div class="">
        <div class="bg-gray-200/50 content-center min-h-[150px] grid font-sans">
            <p class="text-center font-bold text-lg xl:text-3xl">首德之音的秘密</p>
        </div>
        <div class="container mx-auto pb-5 max-w-[1300px]">
            <div class="grid grid-cols-1 lg:grid-cols-2  place-items-center">
                <div class="pb-4 lg:py-4">
                    <img src="https://www.actonchina.com/imgs/ProNews/20240528/17168794074414106.jpg"
                        class="object-cover lg:max-w-[500px] saturate-150">
                </div>
                <div>
                    <div class="leading-7 pb-5" id="contact_sh">
                        <p class="font-bold text-lg xl:text-2xl text-center">首德之音</p>
                        <p class="px-5 mx-auto text-justify py-5 max-w-[500px]">
                            “将声音从钢琴中解放出来，让它的个性和声音整体得到充分的发展。”首德钢琴制造大师们在每一架钢琴运往世界各地前，为他们注入灵魂和生命。</p>
                        <div class="text-center pt-10">
                            <a href="/secret"
                                class="bg-red-600 rounded-3xl px-7 py-[12px] text-sm text-white tracking-wider hover:bg-red-500 duration-200">首德之音的秘密</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="py-5 bg-slate-200/40">
        <div class="container mx-auto py-5 max-w-[1300px]">
            <div class="grid grid-cols-1 lg:grid-cols-2 place-items-center">
                <div>
                    <div class="leading-7 pb-5" id="contact_sh">
                        <p class="font-bold text-lg xl:text-2xl text-center">SAUTER 之音｜这块音板来自于阿尔卑斯山脉</p>
                        <p class="px-5 mx-auto text-justify py-5 max-w-[500px]">
                            从音板开始讲起，探究这来源于阿尔卑斯山的音乐之魂。
                        </p>
                    </div>
                    <div class="text-center py-10">
                        <a href="https://mp.weixin.qq.com/s/cBle7e2q_OYE5SByYwwAbA" target="_blank"
                            class="bg-red-600 rounded-3xl px-7 py-[12px] text-sm text-white tracking-wider hover:bg-red-500 duration-200">第一篇：音板</a>
                    </div>
                </div>
                <div class="">
                    <img src="/images/sound_1.webp" class="object-cover lg:max-w-[450px] saturate-100">
                </div>
            </div>
        </div>
    </div>
    <div class="py-5 bg-white">
        <div class="container mx-auto py-5 max-w-[1300px]">
            <div class="grid grid-cols-1 lg:grid-cols-2 place-items-center">
                <div class="">
                    <img src="/images/sound_2.webp" class="object-cover lg:max-w-[450px] saturate-150">
                </div>
                <div>
                    <div class="leading-7 pb-5" id="contact_sh">
                        <p class="font-bold text-lg xl:text-2xl text-center">SAUTER 之音｜“击弦机中的超跑”速度有多快？</p>
                        <p class="px-5 mx-auto text-justify py-5 max-w-[500px]">
                            如果说音板是一架钢琴的灵魂，那么击弦机一定是钢琴的心脏。
                        </p>
                    </div>
                    <div class="text-center py-10">
                        <a href="https://mp.weixin.qq.com/s/sNfWgdpCTV0hnaU8FRYoJg" target="_blank"
                            class="bg-red-600 rounded-3xl px-7 py-[12px] text-sm text-white tracking-wider hover:bg-red-500 duration-200">第二篇：击弦机</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="py-5 bg-slate-200/40">
        <div class="container mx-auto py-5 max-w-[1300px]">
            <div class="grid grid-cols-1 lg:grid-cols-2 place-items-center">
                
                <div>
                    <div class="leading-7 pb-5" id="contact_sh">
                        <p class="font-bold text-lg xl:text-2xl text-center">SAUTER 之音｜琴弦，钢琴内部的精美艺术品</p>
                        <p class="px-5 mx-auto text-justify py-5 max-w-[500px]">
                            一字排列的一根根琴弦，远远望去散发光泽，俨然是精美的艺术臻品，造就如此清澈动听的“SAUTER 之音”。
                        </p>
                    </div>
                    <div class="text-center py-10">
                        <a href="https://mp.weixin.qq.com/s/mmvCfKc3pWnj_ey-9-k98Q" target="_blank"
                            class="bg-red-600 rounded-3xl px-7 py-[12px] text-sm text-white tracking-wider hover:bg-red-500 duration-200">第三篇：琴弦</a>
                    </div>
                </div>
                <div class="">
                    <img src="/images/detail6.webp" class="object-cover lg:max-w-[450px] saturate-150">
                </div>
            </div>
        </div>
    </div>
    <div class="py-5 bg-white">
        <div class="container mx-auto py-5 max-w-[1300px]">
            <div class="grid grid-cols-1 lg:grid-cols-2 place-items-center">
                <div class="">
                    <img src="/images/sound_4.webp" class="object-cover lg:max-w-[450px] saturate-150">
                </div>
                <div>
                    <div class="leading-7 pb-5" id="contact_sh">
                        <p class="font-bold text-lg xl:text-2xl text-center">SAUTER 之音｜这块闪闪发光铸铁板的秘密</p>
                        <p class="px-5 mx-auto text-justify py-5 max-w-[500px]">
                            铸铁板，钢琴重要的音源部件之一。庞大而结实的部件，与音板、琴弦等共同组成一架钢琴的音源系统。
                        </p>
                    </div>
                    <div class="text-center py-10">
                        <a href="https://mp.weixin.qq.com/s/BLqLw45SV1aNi6PAdSr-LA" target="_blank"
                            class="bg-red-600 rounded-3xl px-7 py-[12px] text-sm text-white tracking-wider hover:bg-red-500 duration-200">第四篇：铸铁板</a>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
    <div class="py-5 bg-slate-200/40">
        <div class="container mx-auto py-5 max-w-[1300px]">
            <div class="grid grid-cols-1 lg:grid-cols-2 place-items-center">
                
                <div>
                    <div class="leading-7 pb-5" id="contact_sh">
                        <p class="font-bold text-lg xl:text-2xl text-center">SAUTER 之音｜码桥，祖辈传承手工艺的结晶</p>
                        <p class="px-5 mx-auto text-justify py-5 max-w-[500px]">
                            任何一种弦鸣乐器都有弦码的装置，有其不同的形状，都是经多次试验改造确定的。一个制作完好的码桥是SAUTER 钢琴工厂两百多年祖辈传承手工艺的结晶。
                        </p>
                    </div>
                    <div class="text-center py-10">
                        <a href="https://mp.weixin.qq.com/s/agOxLq9WjuBVMipWeN4Ueg" target="_blank"
                            class="bg-red-600 rounded-3xl px-7 py-[12px] text-sm text-white tracking-wider hover:bg-red-500 duration-200">第五篇：码桥</a>
                    </div>
                </div>
                <div class="">
                    <img src="/images/sound_5.webp" class="object-cover lg:max-w-[450px] saturate-150">
                </div>
            </div>
        </div>
    </div>
    <div class="py-5 bg-white">
        <div class="container mx-auto py-5 max-w-[1300px]">
            <div class="grid grid-cols-1 lg:grid-cols-2 place-items-center">
                <div class="">
                    <img src="/images/sound_6.webp" class="object-cover lg:max-w-[450px] saturate-100">
                </div>
                <div>
                    <div class="leading-7 pb-5" id="contact_sh">
                        <p class="font-bold text-lg xl:text-2xl text-center">SAUTER 之音｜键盘，开启美妙声音的钥匙</p>
                        <p class="px-5 mx-auto text-justify py-5 max-w-[500px]">
                            许多人往往忽略钢琴键盘的重要性，以为键盘仅仅用来发声，却没有在意它对音色的表现也起着至关重要的作用。
                        </p>
                    </div>
                    <div class="text-center py-10">
                        <a href="https://mp.weixin.qq.com/s/2MMwWXWa6cCStZnE6tw2WA" target="_blank"
                            class="bg-red-600 rounded-3xl px-7 py-[12px] text-sm text-white tracking-wider hover:bg-red-500 duration-200">第六篇：键盘</a>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
    <div class="py-5 bg-slate-200/40">
        <div class="container mx-auto py-5 max-w-[1300px]">
            <div class="grid grid-cols-1 lg:grid-cols-2 place-items-center">
                
                <div>
                    <div class="leading-7 pb-5" id="contact_sh">
                        <p class="font-bold text-lg xl:text-2xl text-center">SAUTER 之音｜弦槌，决定钢琴音色的重要武器</p>
                        <p class="px-5 mx-auto text-justify py-5 max-w-[500px]">
                            有研究表明：钢琴的声学品质，50%决定于音源，25%则取决于弦槌，另25%为其他因素。钢琴弦槌的设计不同，所呈现的音色就不同，充分体现出钢琴弦槌在影响钢琴音色方面的重要作用。
                        </p>
                    </div>
                    <div class="text-center py-10">
                        <a href="https://mp.weixin.qq.com/s/lSeiPfagDY5F4bXnctIOGg" target="_blank"
                            class="bg-red-600 rounded-3xl px-7 py-[12px] text-sm text-white tracking-wider hover:bg-red-500 duration-200">第七篇：弦槌</a>
                    </div>
                </div>
                <div class="">
                    <img src="/images/sound_7.webp" class="object-cover lg:max-w-[450px] saturate-150">
                </div>
            </div>
        </div>
    </div>
    <div class="py-5 bg-white">
        <div class="container mx-auto py-5 max-w-[1300px]">
            <div class="grid grid-cols-1 lg:grid-cols-2 place-items-center">
                <div class="">
                    <img src="/images/sound_8.webp" class="object-cover lg:max-w-[450px] saturate-150">
                </div>
                <div>
                    <div class="leading-7 pb-5" id="contact_sh">
                        <p class="font-bold text-lg xl:text-2xl text-center">SAUTER 之音｜一架SAUTER (首德) 钢琴竟有这么多专利！</p>
                        <p class="px-5 mx-auto text-justify py-5 max-w-[500px]">
                            从音板开始讲起，探究这来源于阿尔卑斯山的音乐之魂。
                        </p>
                    </div>
                    <div class="text-center py-10">
                        <a href="https://mp.weixin.qq.com/s/HgX7k03vmFYuF0fa2KnH7Q" target="_blank"
                            class="bg-red-600 rounded-3xl px-7 py-[12px] text-sm text-white tracking-wider hover:bg-red-500 duration-200">第八篇：专利</a>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';

const pageTitle = ref('首德钢琴历史和首德之音的秘密');

onMounted(() => {
    document.title = pageTitle.value;
});
</script>