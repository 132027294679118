<template>
    <div class="w-full relative">
        <img class="object-cover w-full min-h-[450px] lg:min-h-[390px]" src="/images/care_bg2_lg.jpg">
        <div class="container mx-auto my-5 max-w-[900px] absolute z-5 lg:top-20 top-6 left-0 right-0">
            <div class="flex justify-center">
                <div class="grid grid-cols-1">
                    <p class="font-bold text-xl my-4"><span class="italic font-bold font-serif">Carl Johann</span>卡尔·约翰
                        钢琴序列号验证</p>
                    <p>感谢您选购 <span class="italic font-bold font-serif">Carl Johann</span> 钢琴<br />请在此处验证您的钢琴信息。</p>
                    <p class="text-xs my-4 text-gray-600">请输入六位数字，不含小数点。例:328625</p>
                    <div class="grid grid-cols-1 place-items-center">
                        <div class="flex space-x-2">
                            <input v-for="(val, index) in snParts" :key="index"
                                class="my-5 p-2 font-mono max-w-10 text-center  rounded-md border border-gray-400/70 text-2xl"
                                type="number" maxlength="1" v-model="snParts[index]" @input="focusNext($event, index)"
                                placeholder="" />
                        </div>
                        <button class="rounded-full border border-white text-white text-sm bg-red-700 px-8 py-1"
                            @click="submitSn">
                            验证
                        </button>
                    </div>
                </div>
            </div>
            <div class="flex max-w-[60] justify-center">
                <div class="text-sm px-10 my-10 p-3 bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 text-white"
                    v-if="result">{{ result }}</div>
                <div class="text-sm px-10 my-10 p-3 bg-gradient-to-r from-red-500 via-pink-500 to-red-500 text-white"
                    v-if="resultFail">{{ resultFail }}</div>
            </div>
        </div>
    </div>

</template>
<script setup>
import { ref } from 'vue';

const snParts = ref(Array(6).fill(''));
const sn = ref('');
const result = ref(null);
const resultFail = ref(null);
const snIp = ref('');
const snStatus = ref(null);

const focusNext = (event, index) => {
    if (event.target.value.length >= 1 && index < snParts.value.length - 1) {
        event.target.nextElementSibling.focus();
    }
};

const handleInput = (event, index) => {
    const value = event.target.value;
    if (/^\d$/.test(value)) {
        snParts.value[index] = value;
        if (index < snParts.value.length - 1) {
            event.target.nextElementSibling.focus();
        }
    } else {
        snParts.value[index] = '';
    }
};

const submitSn = async () => {
    sn.value = snParts.value.join('');
    result.value = '';
    resultFail.value = '';

    if (!/^\d{6}$/.test(sn.value)) {
        alert('请输入6位数字。');
        return;
    }

    try {
        const response = await fetch(`/api/sncj/sn/${sn.value}`);
        if (!response.ok) {
            throw new Error('当前系统无法查询，请稍后再试。');
        }
        const data = await response.json();
        result.value = '序列号 [ ' + sn.value + ' ] ,钢琴型号为: ' + data[0].prd_name;

        // 存储查询
        snStatus.value = 'Model:' + data[0].prd_name + ',SITE:SAUTER';
        await getUserIp(); // 获取用户IP地址
        await openUrl(snStatus.value); // 传递snStatus值

    } catch (error) {
        snStatus.value = 'Model:N/A,SITE:SAUTER';
        await getUserIp(); // 获取用户IP地址
        await openUrl(snStatus.value); // 传递snStatus值
        resultFail.value = '没有找到 [ ' + sn.value + ' ] ，请核对后再试。';
        
    }

    // 清空输入框
    snParts.value = Array(6).fill('');
};

const getUserIp = async () => {
    try {
        const response = await fetch('https://api.ipify.org?format=json');
        const data = await response.json();
        snIp.value = data.ip;
    } catch (error) {
        //console.error('获取IP地址失败:', error);
    }
};

const openUrl = async (snStatus) => {
    const baseUrl = "https://www.acton-shanghai.cn/sncontroller/saveReq";
    const url = `${baseUrl}?sn_num=${sn.value}&brd_id=15&sn_status=${snStatus}&sn_ip=${snIp.value}`;
    try {
        const response = await fetch(url);
        const data = await response.json();
       // console.log(url);
    } catch (error) {
        //console.error('请求失败:', error);
    }
};

</script>



<style scoped>
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
</style>