<template>
    <div class="">
        <div class="bg-gray-200/50 content-center min-h-[150px] grid font-sans">
            <p class="text-center font-bold text-3xl">售后支持</p>
        </div>
        <div class="container mx-auto pb-5 max-w-[1300px]">
            <div class="grid grid-cols-1 lg:grid-cols-2  place-items-center">
                <div class="pb-4 lg:py-4">
                    <img src="/images/service.jpg" class="object-cover lg:max-w-[500px]">
                </div>
                <div>
                    <div class="leading-7 pb-5" id="contact_sh">
                        <p class="font-bold text-2xl text-center">为您的乐器提供最佳服务</p>
                        <p class="px-5 mx-auto text-justify py-5 max-w-[500px]">
                            钢琴是一种十分复杂的乐器，由超过8000个用木材、皮革、毛毡和钢制作的零件构成，是一台奇迹般的、高灵敏度的机械和音乐装置。需要特别的护理。</p>
                        <div class="text-center pt-10">
                            <a href="/maintenance"
                                class="bg-red-600 rounded-3xl px-7 py-[12px] text-sm text-white tracking-wider hover:bg-red-500 duration-200">了解详情</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div class="py-5 bg-slate-200/40">
        <div class="container mx-auto py-5 max-w-[1300px]">
            <div class="grid grid-cols-1 lg:grid-cols-2 place-items-center">
                <div>
                    <div class="leading-7 pb-5" id="contact_sh">
                        <p class="font-bold text-2xl text-center">钢琴指南</p>
                        <p class="px-5 mx-auto text-justify py-5 max-w-[500px]">
                            在这里您可以下载所有首德及卡尔约翰立式钢琴和三角钢琴的产品手册。若您发现了符合您要求的钢琴，请通知我们。
                        </p>
                    </div>
                    <div class="text-center py-10">
                        <a href="/download"
                            class="bg-red-600 rounded-3xl px-7 py-[12px] text-sm text-white tracking-wider hover:bg-red-500 duration-200">手册下载</a>
                    </div>
                </div>
                <div class="">
                    <img src="/images/care_bg1_lg.jpg" class="object-cover">
                </div>
            </div>
        </div>
    </div>
    <div class="py-5 bg-white">
        <div class="container mx-auto py-5 max-w-[900px]">
            <div class="grid grid-cols-1">
                <div>
                    <div class="leading-7 pb-5" id="contact_sh">
                        <p class="font-bold text-2xl text-center">保修服务</p>
                        <p class="px-5 mx-auto text-center py-5 max-w-[500px]">
                            了解钢琴的保修政策。</p>
                        <div class="text-center pt-10">
                            <a href="/warranty"
                                class="bg-red-600 rounded-3xl px-7 py-[12px] text-sm text-white tracking-wider hover:bg-red-500 duration-200">了解详情</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';

const pageTitle = ref('SAUTER（首德）钢琴购买指南');

onMounted(() => {
    document.title = pageTitle.value;
});
</script>