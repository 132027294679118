<template>
    <div class="py-6 flex justify-center bg-gray-400 ">
        <div class="w-full min-h-[150] max-w-[950px]">
            <div class="flex justify-between">
                <img class="object-cover max-h-[50px] pl-5 lg:pl-0" src="/sauterlogo.svg">
                <a href="/media"><img class="object-cover max-h-[40px] pl-5 px-5 lg:pl-0"
                        src="/images/wechat_logo.png"></a>
            </div>
        </div>
    </div>
    <div class="py-6 bg-gray-400/20 flex lg:justify-center px-5">
        <div class="max-w-[1300px]">
            <div class="grid grid-cols-2 lg:grid-cols-4 gap-10">
                <div class="min-w-[150px] text-sm">
                    <ul>
                        <li class="font-semibold mb-4">关于首德</li>
                        <li class="text-gray-500"><a href="/history">首德钢琴史</a></li>
                        <li class="text-gray-500"><a href="/explorer">完全德国制造</a></li>
                        <li class="text-gray-500"><a href="/sound">首德之音</a></li>
                    </ul>
                </div>
                <div class="min-w-[150px] text-sm">
                    <ul>
                        <li class="font-semibold mb-4">快速浏览</li>
                        <li class="text-gray-500"><a href="/pianos/sauter/grand/">首德钢琴</a></li>
                        <li class="text-gray-500"><a href="/pianos/design/grand">Peter Maly系列</a></li>
                        <li class="text-gray-500"><a href="/pianos/carl/classic/">卡尔·约翰钢琴</a></li>
                    </ul>
                </div>
                <div class="min-w-[150px] text-sm">
                    <ul>
                        <li class="font-semibold mb-4">购买钢琴</li>
                        <li class="text-gray-500"><a href="/dealer">授权经销商</a></li>
                        <!-- <li class="text-gray-500"><a href="/studio">首德工作室</a></li> -->
                        <li class="text-gray-500"><a href="/download">下载中心</a></li>
                    </ul>
                </div>
                <div class="min-w-[150px] text-sm">
                    <ul>
                        <li class="font-semibold mb-4">服务支持</li>
                        <li class="text-gray-500"><a href="/contact">联系我们</a></li>
                        <li class="text-gray-500"><a href="/care">售后支持</a></li>
                        <li class="text-gray-500"><a href="/maintenance">保养指南</a></li>
                    </ul>
                </div>
            </div>
            <!--div class="grid grid-cols-2 gap-10 lg:grid-cols-8 lg:gap-1 text-xs my-4">
                    <p class="text-gray-500">Hot Line</p>
                    <p class="text-gray-500">116 系列</p>
                    <p class="text-gray-500">122/130 系列</p>
                    <p class="text-gray-500">Peter Maly设计</p>
                    <p class="text-gray-500">CJ 系列</p>
                    <p class="text-gray-500">SE 系列</p>
                    <p class="text-gray-500">VINTAGE 复古系列</p>
            </div-->
            <div class="pt-5 border-b border-gray-700/50 flex w-full">

            </div>
            <div class="grid grid-cols-1 lg:grid-cols-4 text-xs mt-5 text-gray-600 lg:gap-6">
                <p>通利集团 雅登音响乐器（上海）有限公司 </p>
                <p>SAUTER PIANO 首德钢琴中国官网</p>
                <p>沪ICP备16033678号</p>
                <p>版权所有 @2024</p>
            </div>
            <p class="py-4 text-xs text-gray-500"><sup
                    class=" mr-1">注</sup>产品外观设计因批次不同会有所差异（官方图仅供参考，以实物为准），产品价格及材料若有更新，恕不另行通知</p>
        </div>
    </div>
</template>