import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/main.css'; // 主Tialwind CSS V3文件
import LoadingOverlay from './components/common/LoadingOverlay.vue'; // 全局loading样式





//以下创建1在项目编译后删除
// 创建1并添加外部的script标签
//const script = document.createElement('script');
//script.src = 'https://cdn.tailwindcss.com';
//document.head.appendChild(script);

// 创建2并添加全局自定义字体样式
const fontStyles = `
  @font-face {
    font-family: 'OPPOSans-R';
    src: url('/fonts/OPPOSans-R.woff2') format('woff2');
  }
`;

const style = document.createElement('style');
style.innerHTML = fontStyles;
document.head.appendChild(style);

const app = createApp(App);

app.use(router);
app.use(store);
app.component('LoadingOverlay', LoadingOverlay); // 全局注册LoadingOverlay组件

app.mount('#app');