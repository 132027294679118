<template>
    <div v-if="isLoading" class="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-85 z-50">
        <div class="flex space-x-1">
            <div v-for="key in 5" :key="key" class="bg-white h-8 w-2 animate-piano-key"></div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'

const isLoading = ref(true);

onMounted(() => {
    window.addEventListener('load', () => {
        setTimeout(() => {
            isLoading.value = false;
        }, 500);
    });
});
</script>

<style scoped>
@keyframes piano-key {

    0%,
    100% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(10px);
    }
}

.animate-piano-key {
    animation: piano-key 0.5s infinite ease-in-out;
}

.animate-piano-key:nth-child(1) {
    animation-delay: 0s;
}

.animate-piano-key:nth-child(2) {
    animation-delay: 0.1s;
}

.animate-piano-key:nth-child(3) {
    animation-delay: 0.2s;
}

.animate-piano-key:nth-child(4) {
    animation-delay: 0.3s;
}

.animate-piano-key:nth-child(5) {
    animation-delay: 0.4s;
}
</style>