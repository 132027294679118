<template>
    <div class="">
        <div class="bg-gray-200/50 content-center min-h-[150px] grid font-sans">
            <p class="text-center font-bold text-lg xl:text-3xl">SAUTER (首德) 钢琴的制造王朝</p>
        </div>
        <div class="container mx-auto pb-5 max-w-[1300px]">
            <div class="grid grid-cols-1 lg:grid-cols-2  place-items-center">
                <div class="pb-4 lg:py-4">
                    <img src="/images/history.jpg" class="object-cover lg:max-w-[500px]">
                </div>
                <div>
                    <div class="leading-7 pb-5" id="contact_sh">
                        <p class="font-bold text-lg xl:text-2xl text-center">一、创始人：Johann Grimm</p>
                        <p class="px-5 mx-auto text-justify py-5 max-w-[500px] text-sm leading-7 xl:text-lg">
                            <i class="font-bold">德国SAUTER (首德)
                                钢琴</i>始于1819年的施派欣根，205年的历史耀眼夺目，在整个欧洲制琴史上是一颗璀璨的明珠。今天我们追本溯源、回归当初，探索这座钢琴王朝的历史轮回。
                        </p>
                        <div class="text-center pt-10">
                            <a href="/about"
                                class="bg-red-600 rounded-3xl px-7 py-[12px] text-sm text-white tracking-wider hover:bg-red-500 duration-200">首德传奇故事</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="py-5 bg-slate-200/40">
        <div class="container mx-auto py-5 max-w-[1300px]">
            <div class="grid grid-cols-1 lg:grid-cols-2 place-items-center">
                <div>
                    <div class="leading-7 pb-5" id="contact_sh">
                        <p class="font-bold  text-lg xl:text-2xl text-center">二、SAUTER·之名：Carl Sauter</p>
                        <p class="px-5 mx-auto text-justify py-5 max-w-[500px] text-sm leading-7 xl:text-lg">
                            Carl Sauter 是重要人物之一，SAUTER (首德) 的品牌名字便源自于他，并一直沿用至今。
                        </p>
                    </div>
                    <div class="text-center py-10">
                        <a href="https://mp.weixin.qq.com/s/_hcI_sRo-8YqWXVel_ggYg" target="_blank"
                            class="bg-red-600 rounded-3xl px-7 py-[12px] text-sm text-white tracking-wider hover:bg-red-500 duration-200">了解详情</a>
                    </div>
                </div>
                <div class="">
                    <img src="/images/history_2.webp" class="object-cover lg:max-h-96">
                </div>
            </div>
        </div>
    </div>
    <div class="py-5 bg-white">
        <div class="container mx-auto pb-5 max-w-[1300px]">
            <div class="grid grid-cols-1 lg:grid-cols-2  place-items-center">
                <div class="pb-4 lg:py-4">
                    <img src="/images/history_3.webp" class="object-cover lg:max-w-[500px]">
                </div>
                <div>
                    <div class="leading-7 pb-5" id="contact_sh">
                        <p class="font-bold  text-center text-lg xl:text-2xl">三、音乐多面手：Carl Sauter II</p>
                        <p class="px-5 mx-auto text-justify py-5 max-w-[500px] text-sm leading-7 xl:text-lg">
                            在第一次世界大战期间，Johann Sauter 的儿子 Carl Sauter II 担任起公司负责人的角色，SAUTER (首德) 以惊人的业绩度过了困难时期。</p>
                        <div class="text-center pt-10">
                            <a href="https://mp.weixin.qq.com/s/1tyy091PEnRKjeS7nDwUVQ" target="_blank"
                                class="bg-red-600 rounded-3xl px-7 py-[12px] text-sm text-white tracking-wider hover:bg-red-500 duration-200">了解详情</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="py-5 bg-slate-200/40">
        <div class="container mx-auto py-5 max-w-[1300px]">
            <div class="grid grid-cols-1 lg:grid-cols-2 place-items-center">
                <div>
                    <div class="leading-7 pb-5" id="contact_sh">
                        <p class="font-bold text-center text-lg xl:text-3xl">四、稳步奋进：Hans Sauter</p>
                        <p class="px-5 mx-auto text-justify py-5 max-w-[500px] text-sm leading-7 xl:text-lg">
                            我们今天要介绍的主人公——Hans Sauter 从14岁起便随父亲 Carl Sauter II 在公司里学习、工作，与父亲一起见证了这段时光。
                        </p>
                    </div>
                    <div class="text-center py-10">
                        <a href="https://mp.weixin.qq.com/s/WgzHnlwJKWC0l2dExo6eew" target="_blank"
                            class="bg-red-600 rounded-3xl px-7 py-[12px] text-sm text-white tracking-wider hover:bg-red-500 duration-200">了解详情</a>
                    </div>
                </div>
                <div class="">
                    <img src="/images/history_4.webp" class="object-cover">
                </div>
            </div>
        </div>
    </div>
    <div class="py-5 bg-white">
        <div class="container mx-auto py-5 max-w-[1300px]">
            <div class="grid grid-cols-1 lg:grid-cols-2 place-items-center">
                <div class="pb-5">
                    <img src="/images/history_5.webp" class="object-cover max-h-80">
                </div>
                <div>
                    <div class="leading-7 pb-5" id="contact_sh">
                        <p class="font-bold  text-lg xl:text-2xl text-center">五、创新之光：Carl Sauter III</p>
                        <p class="px-5 mx-auto text-justify py-5 max-w-[500px] text-sm leading-7 xl:text-lg">
                            1968年，公司负责人的新旧交接以与以往不同的方式进行。因为年龄尚小，儿子并没有接替父亲，于是Hans Sauter 的弟弟 Carl Sauter III 接管了公司。
                        </p>
                    </div>
                    <div class="text-center py-10">
                        <a href="https://mp.weixin.qq.com/s/XWvYbAvF5QvGX12lHw48Sw" target="_blank"
                            class="bg-red-600 rounded-3xl px-7 py-[12px] text-sm text-white tracking-wider hover:bg-red-500 duration-200">了解详情</a>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <div class="py-5 bg-slate-200/40">
        <div class="container mx-auto pb-5 max-w-[1300px]">
            <div class="grid grid-cols-1 lg:grid-cols-2  place-items-center">
                <div>
                    <div class="leading-7 pb-5" id="contact_sh">
                        <p class="font-bold  text-lg xl:text-2xl text-center">终篇：Peter Maly联名</p>
                        <p class="px-5 mx-auto text-justify py-5 max-w-[500px] text-sm leading-7 xl:text-lg">
                            在1996年，SAUTER (首德) 与欧洲闻名的家居设计大神 Peter Maly (彼得‧马利) 开始了合作，这进一步证明了SAUTER
                            成功地在传统钢琴制作工艺上不断开拓新的领域。</p>
                        <div class="text-center pt-10">
                            <a href="https://mp.weixin.qq.com/s/XZjcozRD3gW1C_m9zA6jmw" target="_blank"
                                class="bg-red-600 rounded-3xl px-7 py-[12px] text-sm text-white tracking-wider hover:bg-red-500 duration-200">了解详情</a>
                        </div>
                    </div>
                </div>
                <div class="pb-4 lg:py-4">
                    <img src="/images/history_6.webp" class="object-cover">
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';

const pageTitle = ref('首德钢琴历史和首德之音的秘密');

onMounted(() => {
    document.title = pageTitle.value;
});
</script>