<template>
  <div class="container mx-auto pb-5 max-w-[1300px]">
    <div class="w-full">
      <img class="object-cover w-full" src="/images/head9.jpg">
    </div>
    <div class="py-6">
      <div class="flex mx-auto w-60 pb-6">
        <img class="object-cover" src="https://www.actonchina.com/imgs/Brand/20180423134145035.jpg">
      </div>
      <div class="mx-auto lg:max-w-[600px] pb-10">
        <!--img class="mx-auto pb-3 max-w-[300px]" src="/images/cj.jpg"-->
        <p class="text-justify leading-8 mx-5"><span class="font-bold">Carl Johann
            (卡尔·约翰)</span>钢琴系列是德国SAUTER钢琴的家族品牌之一，为致敬SAUTER家族百年传承而生，以SAUTER家族六代人始终追求的制琴理念，设计并制作每一台Carl Johann钢琴。</p>
        <!--div class="py-10 text-center">
            <a href="https://mp.weixin.qq.com/s/vjOwdZS-JBhOH4IS3_-bzg" target="_blank"
                class="bg-red-600 rounded-3xl px-7 py-[12px] text-sm text-white tracking-wider hover:bg-red-500 duration-200">探索联名设计</a>
        </div-->
      </div>

      <div class="flex justify-center border-b border-gray-300 mb-14">
        <nav class="flex overflow-x-auto whitespace-nowrap space-x-4">
          <div class="min-h-[34px]" v-for="cat in cat2prd" :key="cat.id">
            <a :href="`/pianos/carl/${cat.cat1_namecn.toLowerCase()}/${cat.id}`" :class="getClass(cat.id)">{{
            cat.cat1_nameen }}</a>
          </div>
        </nav>
      </div>
      <div class="pb-12">
        <p class="text-center text-2xl font-bold">{{ catname }}</p>
      </div>
      <div class=" mx-auto  max-w-[900px]">
        <div class="grid grid-cols-1 gap-9 mx-5 lg:mx-0 lg:grid-cols-2 lg:gap-6">
          <div v-for="product in pianos" :key="product.id">
            <a class="relative" :href="`/carl/detail/${product.id}`">
              <div class=" hover:-translate-y-1 duration-150 hover:opacity-85">
                <div
                  class="flex justify-center rounded-md border border-yellow-600/20 p-6 hover:border-2 hover:border-yellow-600/60 duration-200">
                  <img class="object-cover saturate-100 lg:max-w-[22rem] lg:min-w-[22rem]"
                    :src="`https://www.actonchina.com/${product.prd_cover}`">
                </div>
              </div>
              <div class="absolute z-10 -top-3 left-3">
                <div class="py-1 px-3 text-xs text-white bg-black shadow-md min-w-[114px]">{{
            product.b_nameen.toUpperCase() }}</div>
                <div class="py-1 px-3 text-xs text-white text-center bg-rose-700 shadow-md min-w-[114px]">NEW</div>
              </div>
              <ul>
                <li class="pt-5 text-center">
                  <div class="text-sm">{{ product.cat1_nameen }}</div>
                  <div class="text-xl font-semibold font-mono py-3 min-h-[72px]">{{
            product.prd_name.toUpperCase()
          }}</div>
                </li>
                <div class="pt-6 pb-10 text-center">
                  <a :href="`/carl/detail/${product.id}`"
                    class="border border-gray-600 rounded-full px-10 py-[12px] text-sm tracking-wider hover:bg-black hover:text-white duration-300">了解更多</a>
                </div>
              </ul>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script setup>
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
const pageTitle = ref('卡尔约翰钢琴型号列表 德国SAUTER（首德）钢琴购买指南');

const pianos = ref([]);
const cat2prd = ref([]);
const cat1 = ref('');
const cat2 = ref('');
const catname = ref('');

const route = useRoute();

const fetchProducts = async () => {
  try {
    const { brand, cat1: cat1Param, cat2: cat2Param } = route.params;
    cat1.value = cat1Param;
    cat2.value = cat2Param;

    const seriesMapping = {
      classic: { apiUrl: '/api/product/list/16/cat1/52', catname: 'CLASSIC 系列' },
      se: { apiUrl: '/api/product/list/16/cat1/39', catname: 'SE 系列' },
      vintage: { apiUrl: '/api/product/list/16/cat1/40', catname: 'VINTAGE 系列' },
      legend: { apiUrl: '/api/product/list/16/cat1/54', catname: 'LEGEND 系列' },
    };

    const { apiUrl, catname: seriesName } = seriesMapping[cat1Param] || {};
    const cat2Url = '/api/cat1/list/16/';

    catname.value = seriesName;

    const [pianoResponse, cat2Response] = await Promise.all([
      fetch(apiUrl),
      fetch(cat2Url)
    ]);

    pianos.value = await pianoResponse.json();
    const cat2data = await cat2Response.json();
    cat2prd.value = cat2data.reverse();
  } catch (error) {
    console.error('Error fetching products:', error);
  }
};

const getClass = (catId) => {
  return {
    'py-2 px-4 text-sm lg:text-base hover:text-gray-600 text-gray-800 font-bold': true,
    'border-b-4 border-red-600': catId === route.params.cat2
  };
};

onMounted(() => {
  document.title = pageTitle.value;
  fetchProducts();
});
</script>