import { createRouter, createWebHistory } from 'vue-router'
import Index from '../views/Index.vue'
import SauterList from '../views/products/SauterList.vue'
import DesignList from '../views/products/designList.vue'
import CarlList from '../views/products/CarlList.vue'
import SauterDetail from '../views/products/SauterDetail.vue'
import DesignDetail from '../views/products/DesignDetail.vue'
import CarlDetail from '../views/products/CarlDetail.vue'
import PianoDetail from '../views/products/PianoDetail.vue'
import NewsList from '../views/news/NewsList.vue'
import NewsDetail from '../views/news/NewsDetail.vue'
import DealerList from '../views/dealers/DealerList.vue'
import Studio from '../views/dealers/Studio.vue'
import Technicians from '../views/dealers/Technicians.vue'
import About from '../views/contact/AboutSauter.vue'
import Support from '../views/contact/Support.vue'
import Care from '../views/care/Care.vue'
import Warranty from '../views/care/Warranty.vue'
import Made from '../views/story/Made.vue'
import Sound from '../views/story/Sound.vue'
import Secret from '../views/story/Secret.vue'
import Maintenance from '../views/contact/Maintenance.vue'
import Download from '../views/contact/Download.vue'
import Media from '../views/contact/Media.vue'
import Explorer from '../views/story/Explorer.vue'
import History from '../views/story/History.vue'
import Validation from '../views/care/Validation.vue'
import Chatgpt from '../views/Chatgpt.vue'

const routes = [
  {
    path: '/Chatgpt',
    name: 'Chatgpt',
    component: Chatgpt
  },
  {
    path: '/',
    name: 'Index',
    component: Index
  },
  {
    path: '/pianos/:brand/:cat1/',
    name: 'ProductsBrandCat1',
    component: SauterList
  },
  {
    path: '/pianos/:brand/:cat1/:cat2',
    name: 'ProductsBrandCat2',
    component: SauterList
  },
  {
    path: '/pianos/design/:cat1/',
    name: 'designCat1',
    component: DesignList
  },
  {
    path: '/pianos/design/:cat1/:cat2',
    name: 'designCat2',
    component: DesignList
  },
  {
    path: '/pianos/carl/:cat1/',
    name: 'carlCat1',
    component: CarlList
  },
  {
    path: '/pianos/carl/:cat1/:cat2',
    name: 'carlCat2',
    component: CarlList
  },
  {
    path: '/sauter/detail/:id',
    name: 'SauterDetail',
    component: SauterDetail
  },
  {
    path: '/design/detail/:id',
    name: 'DesignDetail',
    component: DesignDetail
  },
  {
    path: '/carl/detail/:id',
    name: 'CarlDetail',
    component: CarlDetail
  },
  {
    path: '/pianodetail/:id',
    name: 'pianodetail',
    component: PianoDetail
  },
  {
    path: '/news',
    name: 'NewsList',
    component: NewsList
  },
  {
    path: '/news/:id',
    name: 'NewsDetail',
    component: NewsDetail
  },
  {
    path: '/dealer',
    name: 'DealerList',
    component: DealerList
  },
  {
    path: '/retail',
    name: 'retail',
    component: DealerList
  },
  {
    path: '/dealer/:id',
    name: 'DealerProvince',
    component: DealerList
  },
  {
    path: '/technicians',
    name: 'technicians',
    component: Technicians
  },
  {
    path: '/studio',
    name: 'studio',
    component: Studio
  },
  {
    path: '/contact',
    name: 'ContactUs',
    component: Support
  },
  {
    path: '/join',
    name: 'join',
    component: Support
  },
  {
    path: '/support',
    name: 'Support',
    component: Support
  },
  {
    path: '/care',
    name: 'Care',
    component: Care
  },
  {
    path: '/warranty',
    name: 'warranty',
    component: Warranty
  },
  {
    path: '/about',
    name: 'about',
    component: About
  },
  {
    path: '/madeingermany',
    name: 'made',
    component: Made
  },
  {
    path: '/sound',
    name: 'sound',
    component: Sound
  },
  {
    path: '/secret',
    name: 'secret',
    component: Secret
  },
  {
    path: '/maintenance',
    name: 'maintenance',
    component: Maintenance
  },
  {
    path: '/download',
    name: 'download',
    component: Download
  },
  {
    path: '/media',
    name: 'media',
    component: Media
  },
  {
    path: '/explorer',
    name: 'explorer',
    component: Explorer
  },
  {
    path: '/history',
    name: 'history',
    component: History
  },
  {
    path: '/validation',
    name: 'Validation',
    component: Validation
  }


  
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
